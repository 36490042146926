import {animateInViewport} from "../transition";
import {windowSize} from "../layout";

$( '.image-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 10,
		loop: true,
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.post-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		grabCursor: true,
		autoHeight: true,
		slidesPerView: 1.15,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
		},
		loop: false,
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );
